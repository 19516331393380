import React, { ComponentProps } from 'react';
import { Badge, maxBadgeValue } from '../Badge/Badge.tsx';
import {
  badgeBackgroundColorVariableName,
  badgeColorValueVariableName,
} from '../Badge/cssVariableNames.ts';
import { defaultBadgeStyles, inverseBadgeStyles } from '../Badge/styles.ts';
import { ButtonStyle } from './buttonStyle.ts';
import { StyledButtonBadgePositioner } from './components/StyledButtonBadgePositioner.tsx';

const mapButtonStyleToBadgeColorStyles = (buttonStyle: ButtonStyle) => {
  switch (buttonStyle) {
    case 'primary':
    case 'secondary-inverse':
      return inverseBadgeStyles;
    case 'primary-inverse':
    case 'secondary':
    case 'tertiary':
      return defaultBadgeStyles;
  }
};

export const getButtonBadgeVariables = ({
  $buttonStyle,
}: { readonly $buttonStyle: ButtonStyle }) => {
  const { color, backgroundColor } = mapButtonStyleToBadgeColorStyles($buttonStyle);

  return {
    [badgeBackgroundColorVariableName]: backgroundColor,
    [badgeColorValueVariableName]: color,
  };
};

type Props = Pick<ComponentProps<typeof Badge>, 'value'>;

export const ButtonBadge = React.forwardRef<HTMLDivElement, Props>(
  ({ value, ...otherProps }, forwardedRef) => {
    const withValue = typeof value !== 'undefined' && value <= maxBadgeValue;
    const showBadge = value !== 0;

    if (!showBadge) {
      return null;
    }

    return (
      <StyledButtonBadgePositioner withValue={withValue}>
        <Badge ref={forwardedRef} value={value} {...otherProps} />
      </StyledButtonBadgePositioner>
    );
  },
);

ButtonBadge.displayName = 'ButtonBadge';
