import styled, { css } from 'styled-components';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { FontFamily } from '../../../tokens/quarks/fontFamily.ts';
import { FontSize } from '../../../tokens/quarks/fontSize.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { getBorderWidth, getSpecificButtonStyles } from '../utils/stylingUtils.ts';

interface StyledIconButtonProps {
  readonly $activated?: boolean;
  readonly $buttonStyle: ButtonStyle;
  readonly $destructive?: boolean;
  readonly $isFocusVisible: boolean;
  readonly $size: ButtonSize;
  readonly disabled?: boolean;
}

const getButtonSizeStyles = (size: Size, fontSize: FontSize, borderWidth: number) => css`
  border-width: ${px(borderWidth)};
  font-size: ${px(fontSize)};
  height: ${px(size)};
  min-width: ${px(size)};
`;

const getSizeButtonStyles = (buttonStyle: ButtonStyle, size: ButtonSize) => {
  const borderWidth = getBorderWidth(buttonStyle);

  switch (size) {
    case 'small':
      return getButtonSizeStyles(Size.S, FontSize.S, borderWidth);
    case 'medium':
      return getButtonSizeStyles(Size.M, FontSize.S, borderWidth);
    case 'large':
      return getButtonSizeStyles(Size.L, FontSize.M, borderWidth);
  }
};

const baseButtonStyles = css<StyledIconButtonProps>`
  &::before {
    ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
    inset: ${px(-1 * offsetFocus)};
    content: '';
    position: absolute;
    border-radius: ${px(BorderRadius.Pill)};
  }

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${px(BorderRadius.Pill)};
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  font-weight: 700;
  font-family: ${FontFamily.ProductPrimary};
  margin-bottom: 0;
  outline: none;
  position: relative;
  touch-action: manipulation;
  transition: all ${transition250}, border-width 0ms, padding 0ms;
  user-select: none;

  &:hover,
  &:focus,
  & {
    outline: none;
    text-decoration: none;
  }

  &:active {
    outline: none;
  }

  ${({ $buttonStyle, $size }) => getSizeButtonStyles($buttonStyle, $size)};
`;

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  ${baseButtonStyles};
  ${getSpecificButtonStyles};
  ${({ disabled }) =>
    disabled &&
    css`
    &,
    &[disabled] {
      cursor: not-allowed;
    }
  `};
`;
