import styled, { css } from 'styled-components';
import { px } from '../../../tokens/utils/utils.ts';
import {
  badgeSpacingVariableName,
  badgeSpacingWithValueVariableName,
} from '../../Badge/cssVariableNames.ts';
import {
  badgeCoordinateLarge,
  badgeCoordinateLargeWithValue,
  badgeCoordinateMedium,
  badgeCoordinateMediumWithValue,
  badgeCoordinateSmall,
  badgeCoordinateSmallWithValue,
} from '../../Badge/tokens.ts';
import { ButtonSize } from '../buttonSize.ts';

type Props = {
  readonly withValue: boolean;
};

export const getBadgeSpacing = ({ $size }: { readonly $size: ButtonSize }) => {
  switch ($size) {
    case 'small':
      return css`
        ${badgeSpacingVariableName}: ${px(badgeCoordinateSmall)};
        ${badgeSpacingWithValueVariableName}: ${px(badgeCoordinateSmallWithValue)};
      `;
    case 'medium':
      return css`
        ${badgeSpacingVariableName}: ${px(badgeCoordinateMedium)};
        ${badgeSpacingWithValueVariableName}: ${px(badgeCoordinateMediumWithValue)};
      `;
    case 'large':
      return css`
        ${badgeSpacingVariableName}: ${px(badgeCoordinateLarge)};
        ${badgeSpacingWithValueVariableName}: ${px(badgeCoordinateLargeWithValue)};
      `;
  }
};

export const StyledButtonBadgePositioner = styled.div<Props>`
  position: absolute;
  top: var(${({ withValue }) =>
    withValue ? badgeSpacingWithValueVariableName : badgeSpacingVariableName});
  right: var(${({ withValue }) =>
    withValue ? badgeSpacingWithValueVariableName : badgeSpacingVariableName});
`;
